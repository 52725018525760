<template>
  <div class="detil">
    <Head :carouseShow="false" />
    <div class="detil-main">
      <div class="detil-main-weizhi">
        {{ $store.state.staticName.position }}：
        <span @click="goHome">{{ $store.state.staticName.home }}</span>
        >
        {{ detilData.title }}
      </div>
      <div class="detil-main-titles">
        <div class="detil-main-title">{{ detilData.title }}</div>
        <div class="detil-main-synopsis">{{ detilData.synopsis }}</div>
      </div>
      <div class="detil-main-post" v-html="detilData.content"></div>
      <div class="detil-main-post-footer">
        <div @click="handlenNewBefore">
          {{ $store.state.staticName.Last }}：{{ newBefore.title }}
        </div>
        <div @click="handleNewAfter">
          {{ $store.state.staticName.Next }}：{{ newAfter.title }}
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import { mapState } from "vuex";
export default {
  name: "NewDetails",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      id: 0,
      newType: "",
      detilData: {},
      newAfter: "",
      newBefore: "",
    };
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        this.getDetils();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  created() {
    this.id = this.$route.params.newId;
    this.newType = this.$route.params.newType;
    this.getDetils();
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/Home" });
    },
    handlenNewBefore() {
      if (this.detilData.newBefore.id !== 0) {
        this.id = this.detilData.newBefore.id;
        this.getDetils();
      } else {
        this.$message.warning(
          this.$store.state.languageType === "CHINESE"
            ? "没有上一条数据"
            : "No Last data"
        );
      }
    },
    handleNewAfter() {
      if (this.detilData.newAfter.id !== 0) {
        this.id = this.detilData.newAfter.id;
        this.getDetils();
      } else {
        this.$message.warning(
          this.$store.state.languageType === "CHINESE"
            ? "没有下一条数据"
            : "No nest data"
        );
      }
    },
    getDetils() {
      this.$axios({
        url: "/queryNewsDetail",
        method: "get",
        params: {
          type: this.languageType,
          newType: this.newType,
          id: this.id,
        },
      }).then((res) => {
        this.detilData = res.data.value;
        this.newBefore = this.detilData.newBefore;
        this.newAfter = this.detilData.newAfter;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detil {
  background-color: #fff;
  .detil-main {
    width: 1660px;
    margin: 0 auto;
    .detil-main-weizhi {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      span:hover {
        color: #1850af;
        cursor: pointer;
      }
    }
    .detil-main-titles {
      width: 100%;
      margin-top: 43px;
      .detil-main-title {
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        color: #333333;
      }
      .detil-main-synopsis {
        margin-top: 44px;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }
    }
    .detil-main-post {
      margin-top: 44px;
    }
    .detil-main-post-footer {
      margin-top: 38px;
      margin-bottom: 756px;
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      font-weight: 400;
      text-align: left;
      color: #4d4d4d;
      div {
        cursor: pointer;
        width: 350px;
        color: #1850af;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
      }
    }
  }
}
</style>
